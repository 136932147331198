import React, {useEffect, useState} from 'react';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {AnchorLink} from '../AnchorLink';
import {Language, targetLanguage} from '../../../locales/types';

export type BlogArticle =
  | 'local-payment-methods'
  | 'digital-wallet-ecommerce'
  | 'alternative-payment-methods'
  | 'buy-now-pay-later'
  | 'cross-border-ecommerce'
  | 'online-payment-failure-message'
  | 'frictionless-payments'
  | 'cross-border-payments'
  | 'what-is-3d-secure-and-its-advantages-for-e-commerce'
  | 'psd2-and-strong-customer-authentication-sca-explained'
  | 'psd2'
  | 'sca'
  | 'contactless-payments-safer-faster-checkout'
  | 'email-payment-link'
  | 'understanding-pci-compliance'
  | 'what-is-tokenization-and-its-benefits-for-e-commerce'
  | 'what-is-credit-card-processing'
  | 'acquiring-bank-vs-issuing-bank'
  | 'customize-your-ecommerce-checkout-page-with-monei'
  | 'what-are-interchange-fees'
  | 'apple-pay-ecommerce-integration'
  | 'benefits-of-mobile-payments'
  | 'chargebacks-prevention'
  | 'what-is-a-psp'
  | 'instant-payments-europe-sepa-request-to-pay'
  | 'accept-card-payments-on-phone'
  | 'aws-startup-architecture-of-the-year-challenge-monei'
  | 'qr-code-payments'
  | 'omnichannel-payments'
  | 'click-to-pay-with-visa'
  | 'mastercard-click-to-pay'
  | 'ecommerce-conversion-rate-tips-to-help-you-sell-more'
  | 'black-friday-cyber-monday-payment-trends-2022'
  | 'click-to-pay-ecommerce'
  | 'types-of-payment-methods'
  | 'taxi-card-payments'
  | 'freelancer-payment-methods'
  | 'contactless'
  | 'accepting-credit-card-payments-without-merchant-account'
  | 'what-is-recurring-payment'
  | 'what-is-a-payment-gateway'
  | 'how-to-accept-payments-online'
  | 'secure-payments'
  | 'strong-customer-authentication'
  | 'payment-gateway-vs-virtual-pos';

export const BlogLink: React.FC<{slug: BlogArticle; anchor?: string}> = ({
  slug,
  anchor,
  children
}) => {
  const {language} = useI18next();
  const [origin, setOrigin] = useState('https://monei.com');

  useEffect(() => {
    setOrigin(window.location.origin);
  }, []);
  return (
    <AnchorLink
      target="_self"
      href={`${origin + targetLanguage[(language as Language) || 'en']}/blog/${slug}/${
        anchor || ''
      }`}>
      {children}
    </AnchorLink>
  );
};
